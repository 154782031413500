import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Card, CardHeader, Divider, Grid } from '@material-ui/core';

import { gridSpacing } from '../../../store/constant';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getUser } from '../utils/common';
import { useHistory } from 'react-router';
import { deleteAdminAccount, getAdminAccount } from '../../../services/AdminAccountsService';
import AcccountCard from '../../../ui-component/cards/TotalIncomeCard';
import AccountCard from '../../../ui-component/cards/AccountCard';

const useStyles = makeStyles({
    table: {
        minWidth: 350
    }
});

export default function AdminAccount() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [adminAccount, setAdminAccount] = useState({});
    const history = useHistory();
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const user = await getUser();
            if (user) {
                const allBusiness = await getAdminAccount();
                setAdminAccount(allBusiness.data || {});
                setUser(user);
                setLoading(false);
            }
        }
        fetchData();

    }, []);

    const deleteAccount = (id) => {
        setLoading(true);
        const deleteAccount = deleteAdminAccount(id);
        if(deleteAccount){
        setAdminAccount({});
        }
        setLoading(false);
    }
    
    return (
        <React.Fragment>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={6}>
                    <Card>
                        <Grid container rowSpacing={5}>
                            <Grid xs={10}>
                                <CardHeader title="Virtual Account" />
                            </Grid>
                            <Grid xs={2} style={{ marginTop: '20px' }} position="right">
                        <Button variant="outlined" color='error' onClick={() => deleteAccount(adminAccount?._id)} >Delete</Button>

                                <Button  color="primary" href="/admin-account/create" >Create</Button>
                            </Grid>
                        </Grid>
                        <Divider />

                        <AccountCard accountNumber={adminAccount?.accountNumber} bankName={adminAccount?.bankName}></AccountCard>
                        
                        <Divider />

                        {/* <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Account Number</TableCell>
                                        <TableCell align="left">Bank Name</TableCell>
                                        <TableCell align="center">Action&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={adminAccount?._id}>
                                        <TableCell component="th" scope="setting">
                                            {adminAccount?.accountNumber} &nbsp;
                                        </TableCell>
                                        <TableCell component="th" align="left"  scope="setting">
                                            {adminAccount?.bankName} &nbsp;
                                        </TableCell>
                                        <TableCell align="center">
                                        <Button variant="outlined" onClick={() => deleteAccount(adminAccount?._id)} >Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
