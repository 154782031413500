import request from "umi-request";
import { API_URL, USER_TOKEN } from "../config/config.js";

export async function getBusiness() {
  return request(`${API_URL}/api/admin/business`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: USER_TOKEN,
    },
  });
}

export async function getDisabledBusiness() {
  return request(`${API_URL}/api/admin/business/disabled`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: USER_TOKEN,
    },
  });
}

export async function getLogs() {
  return request(`${API_URL}/api/admin/logs`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: USER_TOKEN,
    },
  });
}


export async function createBusiness(body) {
  return request(`${API_URL}/api/admin/business/create`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function activateBusiness(businessId) {
  return request(`${API_URL}/api/admin/business/activate/${businessId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
  });
}

export async function updateBusiness(body, businessId) {
  return request(`${API_URL}/api/admin/business/${businessId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function deactivateBusiness(businessId) {
  return request(`${API_URL}/api/admin/business/deactivate/${businessId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
  });
}

