import React, {lazy} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import AdminAccount from '../views/pages/account';
import CreateAdminAccount from '../views/pages/account/create&update';
import DisabledBusiness from '../views/pages/business/disabledAccounts';

import MainLayout from './../layout/MainLayout';


const DashboardDefault = lazy(() => import('../views/dashboard/Default'));

// const TableBasic = lazy(() => import('../views/forms/tables/TableBasic'));
// const TableDense = lazy(() => import('../views/forms/tables/TableDense'));

// const UtilsTypography = lazy(() => import('../views/utilities/typography'));
// const UtilsColor = lazy(() => import('../views/utilities/color'));
// const UtilsShadow = lazy(() => import('../views/utilities/shadow'));
// const UtilsMaterialIcons = lazy(() => import('../views/utilities/icons/MaterialIcons'));
// const UtilsTablerIcons = lazy(() => import('../views/utilities/icons/TablerIcons'));

const SamplePage = lazy(() => import('../views/sample-page'));

const CreateBusiness = lazy(() => import('../views/pages/business/create&update'));

const BusinessPage = lazy(() => import('../views/pages/business'));

const ActivityLogs = lazy(() => import('../views/pages/business/logTrail'));

const Subscriptions = lazy(() => import('../views/pages/subscription'));

const CreateSubscription = lazy(() => import('../views/pages/subscription/create&update'));

const StaffsPage = lazy(() => import('../views/pages/staffs'));

const CreateStaffPage = lazy(() => import('../views/pages/staffs/create&update'));

const SettingsPage = lazy(() => import('../views/pages/settings'));

const CreateSettingsPage = lazy(() => import('../views/pages/settings/create&update'));

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard',

                '/tables/tbl-basic',
                '/tables/tbl-dense',

                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',

                '/sample-page',
                '/business/create',
                '/business/update',
                '/business/all',
                '/business/logs',
                '/business/disabled/accounts',

                '/subscription/all',
                '/subscription/create',
                '/subscription/update',

                '/staffs/all',
                '/staff/create',

                '/settings',
                '/settings/create',
                '/settings/update',

                '/admin-account',
                '/admin-account/create',
                '/admin-account/update',

            ]}
        >
            <MainLayout showBreadcrumb={true}>
                <Switch location={location} key={location.pathname}>
                        <Route path="/dashboard" component={DashboardDefault} />

                        {/* <Route path="/tables/tbl-basic" component={TableBasic} />
                        <Route path="/tables/tbl-dense" component={TableDense} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} /> */}

                        <Route path="/sample-page" component={SamplePage} />
                        <Route path="/business/all" component={BusinessPage} />
                        <Route path="/business/create" component={CreateBusiness} />
                        <Route path="/business/update" component={CreateBusiness} />
                        <Route path="/business/logs" component={ActivityLogs} />
                        <Route path="/business/disabled/accounts" component={DisabledBusiness} />

                        <Route path="/subscription/all" component={Subscriptions} />

                        <Route path="/subscription/create" component={CreateSubscription} />

                        <Route path="/subscription/update" component={CreateSubscription} />

                        <Route path="/staffs/all" component={StaffsPage} />

                        <Route path="/staff/create" component={CreateStaffPage} />

                        <Route path="/settings/create" component={CreateSettingsPage} />

                        <Route path="/settings/update" component={CreateSettingsPage} />

                        <Route path="/settings" component={SettingsPage} />

                        <Route path="/admin-account/create" component={CreateAdminAccount} />

                        <Route path="/admin-account/update" component={CreateAdminAccount} />

                        <Route path="/admin-account" component={AdminAccount} />
                        

                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
