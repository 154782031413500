import request from "umi-request";
import { API_URL, USER_TOKEN } from "../config/config.js";

export async function getAdminAccount() {
  return request(`${API_URL}/api/admin/account`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: USER_TOKEN,
    },
  });
}


export async function createAdminAccount(body) {
  return request(`${API_URL}/api/admin/account`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateAdminAccount(body, adminId) {
  return request(`${API_URL}/api/admin/account/${adminId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteAdminAccount(adminId) {
  return request(`${API_URL}/api/admin/account/${adminId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    // body: JSON.stringify(body),
  });
}

