import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Card, CardHeader, Divider, Grid } from '@material-ui/core';

import { gridSpacing } from '../../../store/constant';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getUser } from '../utils/common';
import { getDisabledBusiness } from '../../../services/BusinessServices';

const useStyles = makeStyles({
    table: {
        minWidth: 350
    }
});

export default function DisabledBusiness() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [businesses, setBusinesses] = useState([]);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const user = await getUser();
            if (user) {
                const allBusiness = await getDisabledBusiness();
                setBusinesses(allBusiness.data.data);
                setUser(user);
                setLoading(false);
            }
        }
        fetchData();

    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Card>
                        <Grid container rowSpacing={5}>
                            <Grid xs={10}>
                                <CardHeader title="All Business" />
                            </Grid>

                        </Grid>
                        <Divider />
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="center">Email</TableCell>
                                        <TableCell align="center">Role&nbsp;</TableCell>
                                        <TableCell align="center">Status&nbsp;</TableCell>
                                        {/* <TableCell align="center">Action</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {businesses.map((business) => (
                                        <TableRow key={business._id}>
                                            <TableCell component="th" scope="business">
                                                {business.firstName} &nbsp; {business.lastName}
                                            </TableCell>
                                            <TableCell align="center">{business.email}</TableCell>
                                            <TableCell align="center">{business.account?.role}</TableCell>
                                            <TableCell align="center">
                                                {business.account?.status ? (
                                                    <p style={{ color: 'green' }}>Active</p>
                                                ) : (
                                                    <p style={{ color: 'red' }}>Inactive</p>
                                                )}
                                            </TableCell>
                                            {/* <TableCell align="center">
                                                {business.account.status ? (
                                                    <Button variant="contained" color="primary" onClick={() => deactivate(business._id)} >Deactivate</Button>
                                                ) : (
                                                    <Button variant="contained" color="primary" onClick={() => activate(business._id)} >Activate</Button>
                                                )}
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
