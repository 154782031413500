import React from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { getUser } from '../utils/common';
import { createAdminAccount, updateAdminAccount } from '../../../services/AdminAccountsService';
import useStyles from '../../../styles';
import { useHistory } from 'react-router';
import { toast } from 'material-react-toastify';

// import '../../../App.scss';

const CreateAdminAccount = () => {
    const classes = useStyles();
    const [user, setUser] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [formState, setFormState] = useState({
        email: "",
        bvn: null,
        firstName: "",
        lastName: "",
        narration: ""
    });
    
     const history = useHistory();
     const routeState = history.location?.state;
    
     useEffect(() => {
       if (routeState) {
         setIsUpdate(true);
         setFormState({
            email: routeState.account.email,
            bvn: routeState.account.bvn,
            firstname: routeState.account.firstName,
            lastname: routeState.account.lastName,
            narration: routeState.account.narration
         });
       }
     }, [routeState]);
  
    const createUpdateAdminAccount = async () => {
      try {
        setFormState({ ...formState });
        setIsSubmitting(true);
        const body = {
            email: formState.email,
            bvn: formState.bvn,
            firstname: formState.firstName,
            lastname: formState.lastName,
            narration: formState.narration
        }

        if (body.bvn.toString().length < 11 || body.bvn.toString().length > 11 ) {
            toast.error("BVN must be 11 digits");
            setIsSubmitting(false);
            return;
        }

        if (body.email === '' || body.bvn === '') {
          toast.error('Please fill all the fields');
          setIsSubmitting(false);
          return;
        }
        
        if (isUpdate) {
          const result = await updateAdminAccount(body, routeState.account._id);
          if (result.status === 'ok') {
            toast.success('Admin Account updated successfully');
            setIsSubmitting(false);
            setIsUpdate(false);
            history.push('/admin-account');
          }
        } else {
        const response = await createAdminAccount(body);

        if (response.status === 'ok') {
          toast.success("Admin Account created successfully");
        }
        setIsSubmitting(false);
  
        setFormState({
          email: "",
          bvn: null,
          firstName: "",
          lastName: "",
          narration: ""
        });
      }
      } catch (err) {
        toast.error("Error, please try again later");
        setFormState({ ...formState });
        setIsSubmitting(false);

      }
      // console.log(body)
    };
  
    const updateForm = (e) => {
      const { value, name } = e.target;
      setFormState({
        ...formState,
        [name]: value,
      });
    };
    useEffect(() => {
      async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
          setUser(user);
          setLoading(false);
        }
      }
      fetchData();
  
    }, []);
    return (
        <Grid container>
          <Button href="/admin-account" className="btn btn-primary">Back</Button>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={<Typography variant="h5">{isUpdate ? 'Update Admin Account' : 'Create new account'}</Typography>} />
                    <Divider />
                    <CardContent>
                    <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    margin="normal"
                                    name="firstName"
                                    type="text"
                                    variant="outlined"
                                    value={formState?.firstName}
                                    onChange={updateForm}
                                    className={classes.loginput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    margin="normal"
                                    name="lastName"
                                    type="text"
                                    variant="outlined"
                                    value={formState?.lastName}
                                    onChange={updateForm}
                                    className={classes.loginput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    margin="normal"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    value={formState?.email}
                                    onChange={updateForm}
                                    className={classes.loginput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="BVN"
                                    margin="normal"
                                    name="bvn"
                                    type="number"
                                    variant="outlined"
                                    value={formState?.bvn}
                                    onChange={updateForm}
                                    className={classes.loginput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Narration"
                                    margin="normal"
                                    name="narration"
                                    type="text"
                                    variant="outlined"
                                    value={formState?.narration}
                                    onChange={updateForm}
                                    className={classes.loginput}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Box mt={2}>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                onClick={createUpdateAdminAccount}
                                variant="contained"
                                className={classes.login}
                            >
                               {isUpdate ? 'Update Admin Account' : 'Create new account'}
                            </Button>
                        </Box>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CreateAdminAccount;
